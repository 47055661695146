import React from "react";
import { Box, H2 } from "orcs-design-system";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Panel from "src/components/Panel";

import "reactflow/dist/style.css";

import { PersonNodeProvider } from "./Nodes/PersonNode/PersonNodeProvider";

import { ObjectiveStateProvider } from "./contexts/ObjectiveProviderContext";
import { EdgeEventProvider } from "./contexts/EdgeEventProvider";

import ReactFlowLayout from "./ReactFlowLayout";
import { getLayout as getDefaultLayout } from "./layouts/getDefaultLayout";

const StyledTitleBox = styled(Box)`
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  background: radial-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;
  opacity: 1;
  z-index: 20;
  visibility: visible;
  justify-content: center;
  align-items: center;
`;

const StyledPanel = styled(Panel)`
  position: relative;
  box-shadow: none;
  padding: 2px;
  transition: all 0.3s ease;

  ${({ viewType }) =>
    viewType === "strategy" &&
    css`
      border: none;
    `}

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      max-width: calc(100% - 30px);
      max-height: calc(100% - 30px);
      position: fixed;
      z-index: 21;
      padding: 10px;
    `}
`;

const NodeVisualizer = (props) => {
  const {
    title,
    onExpandManagingClick,
    getLayout = getDefaultLayout,
    children = null,
    viewType,
    isFullScreen = false,
    toggleFullScreen = () => {},
  } = props;

  return (
    <EdgeEventProvider>
      <ObjectiveStateProvider nodes={props?.initialNodes} getLayout={getLayout}>
        <PersonNodeProvider onExpandManagingClick={onExpandManagingClick}>
          {isFullScreen && <Overlay onClick={toggleFullScreen} />}
          <StyledPanel
            height="100%"
            isFullScreen={isFullScreen}
            viewType={viewType}
          >
            {title && (
              <StyledTitleBox p="r">
                <H2 sizing="small" weight="light">
                  {title}
                </H2>
              </StyledTitleBox>
            )}
            <ReactFlowLayout
              {...props}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
              getLayout={getLayout}
            >
              {children}
            </ReactFlowLayout>
          </StyledPanel>
        </PersonNodeProvider>
      </ObjectiveStateProvider>
    </EdgeEventProvider>
  );
};

NodeVisualizer.propTypes = {
  ...ReactFlowLayout.propTypes,
  loading: PropTypes.bool,
  noWheelZoom: PropTypes.bool,
  title: PropTypes.string,
  onExpandManagingClick: PropTypes.func,
  shouldUseRemoteControl: PropTypes.bool,
};

export default NodeVisualizer;
