import React, { useCallback, useEffect, useState } from "react";
import { Box } from "orcs-design-system";
import { get, isEqual } from "lodash";
import PropTypes from "prop-types";
import NodeVisualizer from "src/components/NodeVisualizer";
import usePrevious from "src/util/usePrevious";
import { useReactFlowContext } from "src/contexts/reactFlowContext";
import { useOrgChartContext } from "../../context/OrgChartContext";
import { useOrgChartNodesAndEdges } from "../../hooks/useOrgChartNodesAndEdges";
import OrgChatPanel from "../OrgChartPanel";

const TeamVisualiser = ({ shouldUseRemoteControl = true }) => {
  const { data, loading, state, teamId } = useOrgChartContext();
  const context = useReactFlowContext();
  const prevState = usePrevious(state);
  const [initialFitViewTriggered, setInitialFitViewTriggered] = useState(false);

  const { nodes, edges } = useOrgChartNodesAndEdges({ data, state });

  useEffect(() => {
    // Reset the initial fit view triggered flag when the teamId changes (because of page change)
    setInitialFitViewTriggered(false);
  }, [teamId]);

  const afterLayout = useCallback(
    ({ reactFlow, nodes: layoutedNodes }) => {
      if (initialFitViewTriggered) {
        return;
      }

      setInitialFitViewTriggered(true);

      const currentViewNode = layoutedNodes.find(
        (n) => n.id === state.currentViewTeam?.id
      );

      setTimeout(() => {
        const paneRects = document
          .querySelector(".react-flow__renderer")
          .getBoundingClientRect();

        reactFlow.setViewport(
          {
            zoom: 0.8,
            y: -30,
            x: paneRects.width / 2 - get(currentViewNode, "width", 400) / 2,
          },
          { duration: 500 }
        );
      }, 100);
    },
    [initialFitViewTriggered, state.currentViewTeam]
  );

  const shouldPreventUpdatingNodes = useCallback(() => {
    return (
      state?.expandedPanel &&
      prevState?.expandedPanel &&
      !isEqual(state?.expandedPanel, prevState?.expandedPanel)
    );
  }, [prevState?.expandedPanel, state]);

  return (
    <Box height="100%">
      <NodeVisualizer
        loading={loading}
        initialNodes={nodes}
        initialEdges={edges}
        afterLayout={afterLayout}
        noWheelZoom
        animatedNodesEnabled
        shouldPreventUpdatingNodes={shouldPreventUpdatingNodes}
        isFullScreen={context?.isFullScreen}
        toggleFullScreen={context?.toggleFullScreen}
        shouldUseRemoteControl={shouldUseRemoteControl}
      >
        <OrgChatPanel />
      </NodeVisualizer>
    </Box>
  );
};

TeamVisualiser.propTypes = {
  shouldUseRemoteControl: PropTypes.bool,
};

export default React.memo(TeamVisualiser);
