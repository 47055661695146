import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import { isEmpty, max, get } from "lodash";

import DateSettingsPropType from "src/custom-prop-types/dateSettings";
import { useFiltersForQuery } from "src/contexts/filterAndFte/FilterAndFteContext";

import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import {
  localStorageLayoutKey,
  getLayoutFromLocalStorage,
  saveLayoutToLocalStorage,
} from "./localStorage";
import ImportDate from "./ImportDate";
import ImportDateError from "./ImportDateError";
import cards from "./Cards";

const valueInsightHeight = 34;
const singleStatCardHeight = 7;

const defaultLayout = () => {
  const layout = {
    lg: [
      {
        i: "person",
        x: 0,
        y: 0,
        h: singleStatCardHeight,
        w: 1,
        minH: singleStatCardHeight,
        maxH: singleStatCardHeight,
        isResizable: false,
      }, // circle pack card
      {
        i: "gender",
        x: 0,
        y: 1,
        h: singleStatCardHeight,
        w: 1,
        minH: singleStatCardHeight,
        maxH: singleStatCardHeight,
        isResizable: false,
      }, // min max cards not responsive to height changes
      {
        i: "layers",
        x: 0,
        y: 2,
        h: singleStatCardHeight,
        w: 1,
        minH: singleStatCardHeight,
        maxH: singleStatCardHeight,
        isResizable: false,
      },
      {
        i: "spans",
        x: 0,
        y: 4,
        h: singleStatCardHeight,
        w: 1,
        minH: singleStatCardHeight,
        maxH: singleStatCardHeight,
        isResizable: false,
      },
      { i: "allocation", x: 0, y: 5, h: 20, w: 1, minH: 16 }, // Pie chart
      { i: "company", x: 0, y: 6, h: valueInsightHeight, w: 1, minH: 14 }, // circle pack card
      { i: "country", x: 1, y: 0, h: valueInsightHeight, w: 1, minH: 14 }, // circle pack card
      { i: "location", x: 1, y: 1, h: valueInsightHeight, w: 1, minH: 14 }, // circle pack card
      { i: "division", x: 1, y: 2, h: valueInsightHeight, w: 1, minH: 14 }, // circle pack card
      { i: "role", x: 1, y: 3, h: valueInsightHeight, w: 1, minH: 14 }, // circle pack card
      {
        i: "teamFilterMatch",
        x: 2,
        y: 1,
        h: valueInsightHeight,
        w: 1,
        minH: 14,
      }, // circle pack card
      {
        i: "teamFilterMatchStat",
        x: 2,
        y: 0,
        h: singleStatCardHeight,
        w: 1,
        minH: singleStatCardHeight,
        maxH: singleStatCardHeight,
        isResizable: false,
      }, // min max card
      {
        i: "teamTypeStats",
        x: 0,
        y: 5,
        h: 6,
        w: 2,
        minW: 2,
        minH: 6,
        isResizable: false,
      }, // min max card
    ],
  };

  return layout;
};

const updateLayoutCard = (mediaLayout, cardKey, mergedCardValues) => {
  if (!mediaLayout) {
    return;
  }

  const card = mediaLayout.find((c) => c.i === cardKey);
  if (!card) {
    return;
  }

  Object.assign(card, mergedCardValues);
};

const resizeLayoutBasedOnInsightData = (layout, insights = {}) => {
  const teamTypeStats =
    insights.statLists &&
    insights.statLists.find((s) => s.key === "teamTypeStatistics");

  if (teamTypeStats) {
    const extraPadding = 0.236 * teamTypeStats.items.length + 0.448;
    const h =
      max([6, 6 + (teamTypeStats.items.length - 1) * 2.5]) + extraPadding;
    updateLayoutCard(layout.xs, "teamTypeStats", { h });
    updateLayoutCard(layout.sm, "teamTypeStats", { h });
    updateLayoutCard(layout.md, "teamTypeStats", { h });
    updateLayoutCard(layout.lg, "teamTypeStats", { h });
  }
};

const initialLayoutState = (insights) => {
  const layout =
    getLayoutFromLocalStorage(localStorageLayoutKey()) || defaultLayout();

  resizeLayoutBasedOnInsightData(layout, insights);
  return layout;
};

const calculateCardExpansion = (cardLayout, layoutKey, itemCount) =>
  cardLayout.i === layoutKey
    ? { ...cardLayout, h: itemCount * 2 + 11 - Math.floor(itemCount / 10) }
    : cardLayout;

const calculateNewLayoutForCardExpansion = (
  mediaSizeLayout,
  layoutKey,
  itemCount
) =>
  mediaSizeLayout
    ? mediaSizeLayout.map((cardLayout) =>
        calculateCardExpansion(cardLayout, layoutKey, itemCount)
      )
    : undefined;

const ResponsiveGrid = ({
  autoSize = true,
  insights,
  error,
  importDate,
  loading,
  size,
  dateSettings: { comparisonMode = false, fromDate, toDate } = {},
  fteMode,
}) => {
  const featureFlags = useWorkspaceFeatureFlags();
  const filter = useFiltersForQuery();
  const [dataGridLayout, updateDataGridLayout] = useState(
    initialLayoutState(insights)
  );

  useEffect(() => {
    updateDataGridLayout(initialLayoutState(insights));
  }, [insights]);

  if (!isEmpty(error)) {
    return <ImportDateError fromDate={fromDate} toDate={toDate} />;
  }

  const updateCardSize = (layoutKey, itemCount) => {
    const layout = {
      sm: calculateNewLayoutForCardExpansion(
        dataGridLayout.sm,
        layoutKey,
        itemCount
      ),
      md: calculateNewLayoutForCardExpansion(
        dataGridLayout.md,
        layoutKey,
        itemCount
      ),
      lg: calculateNewLayoutForCardExpansion(
        dataGridLayout.lg,
        layoutKey,
        itemCount
      ),
    };
    resizeLayoutBasedOnInsightData(layout, insights);
    updateDataGridLayout(layout);
  };

  const layoutChange = (layout, layouts) => {
    updateDataGridLayout(layouts);
    resizeLayoutBasedOnInsightData(layouts, insights);
    saveLayoutToLocalStorage(layouts, defaultLayout(), localStorageLayoutKey());
  };

  return (
    <>
      <ImportDate
        importDate={importDate}
        loading={loading}
        toDate={toDate}
        fromDate={fromDate}
        comparisonMode={comparisonMode}
        fteMode={fteMode}
      />
      <ResponsiveGridLayout
        className="layout"
        layouts={dataGridLayout}
        autoSize={autoSize}
        rowHeight={11}
        margin={[16, 16]}
        containerPadding={[1, 1]}
        breakpoints={{ lg: 1600, md: 1200, sm: 800, xs: 500 }}
        cols={{ lg: 3, md: 3, sm: 2, xs: 1 }}
        width={size.width}
        draggableHandle=".draggable"
        onLayoutChange={layoutChange}
      >
        {cards({
          insights,
          comparisonMode,
          fteMode,
          updateCardSize,
          loading,
          displaySubDivisions: get(
            filter,
            "divisions.includeSubDivisions",
            false
          ),
          features: featureFlags.summaryInsightPanels,
        })}
      </ResponsiveGridLayout>
    </>
  );
};

ResponsiveGrid.propTypes = {
  autoSize: PropTypes.bool,
  size: PropTypes.object,
  dateSettings: DateSettingsPropType,
  fteMode: PropTypes.bool,
  insights: PropTypes.object,
  error: PropTypes.object,
  importDate: PropTypes.string,
  loading: PropTypes.bool,
};

export default withSize()(ResponsiveGrid);

export { defaultLayout };
