import { FloatingPanels } from "orcs-design-system";
import React, { useMemo } from "react";
import { Panel } from "reactflow";
import { isEmpty } from "lodash";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import ViewOptions from "./panels/ViewOptions";
import Properties from "./panels/Properties";
import Legend from "./panels/Legend";
import { PANEL_ID } from "./const";
import { Details } from "./panels/Details/Details";

const LegendPanel = {
  id: PANEL_ID.legend,
  iconName: "palette",
  title: "Legend",
  content: <Legend />,
};

const ViewOptionsPanel = {
  id: PANEL_ID.viewOptions,
  iconName: "cog",
  title: "View Options",
  content: <ViewOptions />,
};

const PropertiesPanel = {
  id: PANEL_ID.properties,
  iconName: "filter",
  title: "Properties",
  content: <Properties />,
};

const DetailsPanel = {
  id: PANEL_ID.details,
  iconName: "info-circle",
  title: "Details",
  content: <Details />,
};

const OrgChartPanel = () => {
  const { state } = useOrgChartContext();

  // calc panels to display based on state
  const panelsToDisplay = useMemo(() => {
    const panels = [];

    // legend panel (always show)
    panels.push(LegendPanel);

    // view options panel
    panels.push(ViewOptionsPanel);

    // only show properties panel if there are loaded teams or members
    if (!isEmpty(state?.teams) || !isEmpty(state?.members)) {
      panels.push(PropertiesPanel);
    }

    // legend panel (always show)
    panels.push(DetailsPanel);

    return panels;
  }, [state]);

  return (
    <Panel position="top-right">
      <FloatingPanels
        panels={panelsToDisplay}
        defaultSelectedPanelId={PANEL_ID.details}
        containerHeight={700}
        position={{
          right: 0,
          top: 0,
        }}
        alignPanels="right"
      />
    </Panel>
  );
};

export default OrgChartPanel;
