import React from "react";
import PropTypes from "prop-types";
import {
  TextInput,
  Toggle,
  Select,
  Box,
  P,
  Small,
  Notification,
  H3,
} from "orcs-design-system";
import { getOptionByValue } from "src/util/selectUtil";

const COLOR_OPTIONS = ["default", "warning", "danger", "success"].map((o) => ({
  label: o,
  value: o,
}));

const VISUAL_TREATMENT_OPTIONS = [
  { label: "Large banner at the top of the page", value: "top-banner" },
  { label: "Small banner at the bottom of the page", value: "bottom-banner" },
];

export const Copywriting = ({ global }) => {
  if (global) {
    return (
      <Box mb="xl">
        <P mb="r">
          Here you can set separate announcements for ADMIN/POWER role users and
          DIRECTORY role users.
        </P>
        <P mb="r">
          These announcements will be shown for all workspaces in this
          environment.
        </P>
        <P mb="r">
          You can also select the colour of the announcements and choose to
          toggle each as active, which will then show the announcements
          throughout the app.
        </P>
        <Small>
          Note: After making changes and saving, refresh the page to see changes
          in action.
        </Small>
      </Box>
    );
  }
  return (
    <Box mb="xl">
      <P mb="r">
        Here you can set separate announcements for ADMIN/POWER role users and
        DIRECTORY role users.
      </P>
      <P mb="r">These announcements will be shown only for this workspace.</P>
      <P mb="r">
        You can also select the colour of the announcements and choose to toggle
        each as active, which will then show the announcements throughout the
        app for this workspace.
      </P>
      <Small>
        Note: After making changes and saving, refresh the page to see changes
        in action.
      </Small>
    </Box>
  );
};

Copywriting.propTypes = {
  global: PropTypes.bool,
};

const formatStyleVariantOptionLabel = ({ label, value }) => {
  return (
    <Notification noWrap={true} colour={value} closable={false}>
      {label}
    </Notification>
  );
};

const Settings = ({ title, notification, updateNotification }) => {
  const onChangeMessage = (e) => {
    updateNotification({
      ...notification,
      message: e.target.value,
    });
  };

  const onChangeColour = (option) => {
    updateNotification({
      ...notification,
      colour: option.value,
    });
  };

  const onVisualTreatmentChange = (option) => {
    updateNotification({
      ...notification,
      visualTreatment: option.value,
    });
  };

  const onChangeActive = (e) => {
    updateNotification({
      ...notification,
      isActive: e.target.checked,
    });
  };

  return (
    <Box>
      <H3 weight="bold" mb="s">
        {title}
      </H3>
      <Box mb="r">
        <TextInput
          name="notification"
          type="text"
          id="notification"
          label="Announcement"
          placeholder="What would you like this app wide announcement to say?"
          value={notification.message}
          onChange={onChangeMessage}
          fullWidth
        />
      </Box>
      <Box mb="r">
        <Select
          options={COLOR_OPTIONS}
          label="Announcement Colour"
          inputId="NotificationColour"
          value={getOptionByValue(COLOR_OPTIONS, notification.colour)}
          onChange={onChangeColour}
          formatOptionLabel={formatStyleVariantOptionLabel}
        />
      </Box>
      <Box mb="r">
        <Select
          options={VISUAL_TREATMENT_OPTIONS}
          label="Visual treatment"
          inputId="VisualTreatment"
          value={getOptionByValue(
            VISUAL_TREATMENT_OPTIONS,
            notification.visualTreatment
          )}
          onChange={onVisualTreatmentChange}
        />
      </Box>
      <Toggle
        id={`${title}-Toggle`}
        label="Active"
        checked={notification.isActive}
        onChange={onChangeActive}
        mb="r"
      />
    </Box>
  );
};

Settings.propTypes = {
  title: PropTypes.string,
  notification: PropTypes.object,
  updateNotification: PropTypes.func,
};

export default Settings;
